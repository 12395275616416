/* COLORS WIP */

.container {
    background-color: var(--first-color);
    padding: 4rem 0 4rem;
}

.footer_container {
    display: flex;
    padding: 1rem 0 2rem;
    flex-direction: column;
    flex-wrap: wrap;
}

/* socials */


.footer_links {
    align-self: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: var(--mb-2);
}

.social{
    display: flex;
    justify-content: space-around;
    padding: 5%; 
    width: 80%;  
}
.item {
    padding: 2%; 
    
}
.linkedin{
    color: var(--title-color-dark);
}
.linkedin :hover{
    color: #0e76a8;
}
.element {
    color: var(--title-color);
    font-size: 1.5rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    display: inline-flex;
}


.element:hover {
    color: white;
    
}
/* line break*/
.linebreak {
    height: 1px;
    color: white;
    border-radius: 2rem;
}

/* bottom line */

.base_text {
    display: block;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}

